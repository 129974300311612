img{
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}
.main-sidebar, .main-sidebar{
  width: 170px;
}
@media (min-width: 768px){
  body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav) .content-wrapper, body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav) .main-footer, body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav) .main-header {
    margin-left: 170px;
  }
}
.main-sidebar .sidebar{
  font-size: 14px;
}
.nav-sidebar .nav-item .nav-link{
  cursor: pointer;
}
.sidebar-mini .main-sidebar .nav-link, .sidebar-mini-md .main-sidebar .nav-link, .sidebar-mini-xs .main-sidebar .nav-link{
  width: calc(170px - 0.5rem * 2);
}
.sidebar .nav-item.active{
  background-color: #007bff;
  color: #fff;
  border-radius: 0.25rem;
}
.sidebar .nav-treeview .nav-item.active .nav-link{
  color: #fff;
}
.editorWrap .imageUploadBtn{
  margin-left: -6px;
  margin-bottom: 6px;
}
.content .makeMediaGallery{
  font-weight: 700;
}
.content a.galleryUploadBtn{
  width: 100%;
}
.content .galleryImgWrapper{
  background: #f2f2f2cf;
}
.content .galleryImgElement{
  max-width: 100%;
  max-height: 234px;
  margin: 0 auto;
  display: block;
}
.content .featuredImg{
  max-width: 100%;
  max-height: 234px;
  margin: 0 auto;
  display: block;
}
.publishDate .form-control{
  padding: 0 0 0 5px;
  font-size: 13px;
}
.modal-open .modal.fade.show{
  background-color: rgba(0,0,0,0.6);
}
.uploadMediaModal .nav.nav-tabs .nav-item .nav-link{
  cursor: pointer;
}
.uploadMediaModal .card-body .tab-content .tab-pane.addFiles{
  padding: 67px 0 67px 0;
}
.uploadMediaModal .uploadImg{
  display: none;
}
.content .mediaLibrary{
  max-height: 488px;
  overflow: auto;
  /* position: absolute; */
}
.content .mediaLibrary ul{
  list-style: none;
  padding: 2px 8px 8px;
}
.content .mediaLibrary ul li{
  position: relative;
  float: left;
  padding: 8px;
  margin: 0;
  color: #3c434a;
  cursor: pointer;
  list-style: none;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  width: 25%;
  box-sizing: border-box;
  width: 20%;
  box-shadow: inset 0 0 0 5px #fff, inset 0 0 0 7px #c3c4c7;
}
.content .mediaLibrary ul li.focused{
  box-shadow: inset 0 0 0 5px #fff, inset 0 0 0 8px #599fdc;
}
.content .mediaLibrary ul li .attachmentPreview{
  position: relative;
  box-shadow: inset 0 0 15px rgb(0 0 0 / 10%), inset 0 0 0 1px rgb(0 0 0 / 5%);
  background: #f0f0f1;
  cursor: pointer;
}
.content .mediaLibrary ul li .attachmentPreview::before{
  content: "";
  display: block;
  padding-top: 100%;
}
.content .mediaLibrary ul li .attachmentPreview .thumbnail{
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 1;
  transition: opacity .1s;
}
.content .mediaLibrary ul li .attachmentPreview .thumbnail::after{
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: inset 0 0 0 1px rgb(0 0 0 / 10%);
  overflow: hidden;
}
.content .mediaLibrary ul li .attachmentPreview .thumbnail .centered{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translate(50%,50%);
}
.content .mediaLibrary ul li .attachmentPreview .thumbnail .centered img{
  transform: translate(-50%,-50%);
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
}
.content .mediaLibrary ul li .check{
  display: none;
  height: 24px;
  width: 24px;
  padding: 0;
  border: 0;
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  outline: 0;
  background: #f0f0f1;
  cursor: pointer;
  box-shadow: 0 0 0 1px #fff, 0 0 0 2px rgb(0 0 0 / 15%);
}
.content .mediaLibrary ul li .check .mediaModalIcon{
  display: block;
  background-position: -1px 0;
  height: 15px;
  width: 15px;
  margin: 5px;
  background-image: url(/public/media/uploader-icons-2x.png);
  background-size: 134px 15px;
  background-repeat: no-repeat;
}
.content .mediaLibrary ul li .check.selected .mediaModalIcon{
  background-position: -1px 0;
}
.content .mediaLibrary ul li .check.selected{
  display: block;
}
.content .mediaLibrary ul li .check.selected.focus .mediaModalIcon{
  background-position: -21px 0;
}
.content .mediaLibrary ul li .check.selected.focus{
  background-color: #2271b1;
  box-shadow: 0 0 0 1px #fff, 0 0 0 2px #2271b1;
}
.content .attachmentInfo{
  overflow: hidden;
  min-height: 60px;
  margin-bottom: 16px;
  line-height: 1.5;
  color: #646970;
  border-bottom: 1px solid #dcdcde;
  padding-bottom: 11px;
} 
.content .attachmentInfo .thumbnail{
  position: relative;
  float: left;
  max-width: 120px;
  max-height: 120px;
  margin-top: 5px;
  margin-right: 10px;
  margin-bottom: 5px;
} 
.content .attachmentInfo .thumbnail img{
  display: block;
  max-width: 120px;
  max-height: 120px;
  margin: 0 auto;
} 
.content .attachmentInfo .thumbnail img::after{
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: inset 0 0 0 1px rgb(0 0 0 / 15%);
  overflow: hidden;
} 
.mediaLibraryWrap .details{
  font-size: 12px;
  max-width: 100%;
}
.mediaLibraryWrap .details .filename{
  word-wrap: break-word;
}
.mediaLibraryWrap .details a, .mediaLibraryWrap .details a:not([href]):not([class]):hover{
  color: #0056b3;
  text-decoration: none;
  cursor: pointer;
}
.mediaLibraryWrap .attachmentSettings label, .mediaLibraryWrap .attachmentSettings .form-control{
  font-weight: 400;
  font-size: 13px;
}
.mediaLibraryWrap .attachmentSettings .form-group{
  margin-bottom: 3px;
}
.mediaLibraryWrap .attachmentSettings .form-control{
  padding: 6px 0 1px 8px;
  height: 50px;
  font-size: 13px;
}
.login-page .invalid-feedback, .form.invalid-feedback{
  display: block;
}
.content .publishData{
  margin-bottom: 15px;
}
.content .publishData.warning{
  background: yellow;
  width: 80px;
  font-weight: 500;
  padding-left: 10px;
  border-radius: 6px;
}
.content .publishData.success{
  background: green;
  width: 80px;
  font-weight: 500;
  padding-left: 10px;
  border-radius: 6px;
  color: #fff;
}
.content .galleryCount{
  margin-bottom: 15px;
}